/* Basic styling for the message section */
.containerm {
    max-width: 1200px;
    margin: auto;
}

img {
    max-height: 400px;
    width: 100%;
    object-fit: contain;
}

/* Social media icons */
.text-facebook {
    color: #3b5998;
}

.text-instagram {
    color: #e4405f;
}

.text-twitter {
    color: #1da1f2;
}

.text-linkedin {
    color: #0077b5;
}

.text-dribbble {
    color: #ea4c89;
}

.text-pinterest {
    color: #bd081c;
}

.list-inline-item i {
    transition: color 0.3s ease, transform 0.3s ease;
}

.list-inline-item a:hover i {
    color: #000;
    transform: scale(1.2);
}

/* Form input styling */
.form-control {
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 10px;
}

.form-control-lg {
    font-size: 1.25rem;
}

/* Button styling with gradient */
.btn-custom {
    background:  #2c5d63;
    border: none;
    padding: 10px 20px;
    font-size: 1.25rem;
    color: white;
    transition: background 0.3s ease, transform 0.3s ease;
    border-radius: 5px; /* Optional: Adding rounded corners */
}

.btn-custom:hover {
   
    transform: translateY(-2px);
}


.dark-overlay {
    position: relative;
  }
  
  .bg-image {
    width: 100%;
    height: auto;
  }
  
  .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }



