


/* 
carousel
*/
.carousel-item img{
  
    object-fit: fill;
      max-height: 600px;
    }
/* navbar */
nav {
    display: flex;
    padding: 4px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .logo {
    width: 100px;
    padding: 0%;
  }
  
  nav ul li {
    display: inline-block;
    list-style: none;
    margin: 20px 20px;
    position: relative; /* Make the parent of dropdown relative */
  }
  
  nav ul li a {
    color: #333;
    text-decoration: none;
    padding: 0.5em;
    font-size: 1.2em;
    font-weight: 520;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  
  /* Hover effect */
  nav ul li a:hover {
    color: #a2c11c;
  }
  
  /* Dropdown styling */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Active dropdown link */
  .dropdown-content a.active {
    background-color: #a2c11c;
    color: white;
  }
  







nav .fas{
    display: none;
}
@media only screen and (max-width:600px) {
  
    .header-text{
        margin-top: 100%;
        font-size: 16px;
    }
    .header-text h1{
      
        font-size: 30px;
    }
    .header-text span{
    font-size: 30px;}
nav .fas{
    display: block;
    font-size: 30px;
    position: absolute;
    top: 40px;
    right: 35px;}
nav ul{
    background:  rgba(81, 117, 105, 0.8);;
    position: fixed;
    top: 0;
    right: -200px;
    width: 200px;
    height: 100vh;
    padding-top: 50px;
z-index: 2;
transition: right 0.5s;}
nav ul li{
    display: block;
    margin: 25px;
}
nav ul .fas{
    position: absolute;
    top: 25px;
    left: 25px;
    cursor: pointer;
}
.sub-title{
    font-size: 40px;
}
.about-col-1 .about-col-2{
    flex-basis: 100%;
}
.about-col-1 {
    margin-bottom: 30px;
}
.about-col-2 {
    font-size: 14px;
}
.tab-links{
    font-size: 16px;
margin-right: 20px;
}







.dropdown-content  .dropdown  {
  display: none;
}






}





/* popular grades*/
/* Basic styling for the image container */
.image-container {
    display: inline-block;
    position: relative;
    overflow: hidden; /* Ensures the image doesn't overflow its container */
    text-align: center; /* Centers the heading */
    width: 100%; /* Responsive width */
    max-width: 250px; /* Maximum width for larger screens */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    margin: 10px; 
    text-decoration: none;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
   border-radius: 12px;
  
}

.image-container img {
    display: block;
    width: 100%;
    height: auto;
    
}
.card-text {
  margin: 0;
 /* Slightly larger font size */
  font-weight: bold; /* Bold text */
}
.image-container:hover {
    transform: scale(1.1); /* Increases the size by 10% */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Optional: Adds a shadow for a nice effect */
    color: #a2c11c;

}

.view-more-btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  background-color:  #a2c11c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-more-btn:hover {
  background-color: #a2c11c;
  transform: scale(1.2);
}
/* Responsive adjustments */
@media (max-width: 768px) {
    .image-container {
        max-width: 250px; /* Adjust the max-width for tablets */
        color: #a2c11c;
    }

   
}

@media (max-width: 576px) {
    .image-container {
        max-width: 200px; /* Adjust the max-width for mobile devices */
        color: #a2c11c;
    }

  
}



/* all headings*/

hr{
    color: #188389;
}

.headings{
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color:#188389

    ;
    padding: 1rem;
    font-weight: 750;
}


/* services*/#services {
  padding: 30px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
  padding: 0 20px;
}

.services-list div {
  background: #ffffff;
  padding: 40px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.7s, transform 0.5s, box-shadow 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.services-list div h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #188389

  ;
}

.services-list div:hover {
  background: linear-gradient(181deg, rgba(199, 218, 114, 0.4), #a2c11c);
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.services-list div p {
  color: #666666;
  line-height: 1.5;
}
@media (hover:none){
  .services-list div{
      background: linear-gradient(181deg, rgba(199, 218, 114, 0.4), #a2c11c);
  } 
}

/* whatsapp icon */
/* whats app icon*/
.whatsapp-button-container {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1000;
  }
  
  .whatsapp-icon {
    color: #25d366;
    transition: transform 0.3s ease-in-out;
  }
  
  .whatsapp-icon:hover {
    transform: scale(1.2); /* Increase icon size on hover */
  }



/* message container*/
  /* Basic styling for the message section */
.containerm {
    max-width: 1200px;
    margin: auto;
}

img {
    max-height: 400px;
    width: 100%;
    object-fit: contain;
}

/* Social media icons */
.text-facebook {
    color: #3b5998;
}

.text-instagram {
    color: #e4405f;
}

.text-twitter {
    color: #1da1f2;
}

.text-linkedin {
    color: #0077b5;
}



.text-gmail {
    color: #D44638;
}


.text-whatsapp {
    color: #25D366;
}



.list-inline-item i {
    transition: color 0.3s ease, transform 0.3s ease;
}

.list-inline-item a:hover i {
    color: #000;
    transform: scale(1.2);
}

/* Form input styling */
.form-control {
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 10px;
}

.form-control-lg {
    font-size: 1.25rem;
}

.small-placeholder::placeholder {
    font-size: 0.75em; /* Adjust the size as needed */
    color: #6c757d; /* Optional: Adjust the color as needed */
  }

/* Button styling with gradient */
.btn-custom {
    background:   #a2c11c;;
    border: none;
    padding: 10px 20px;
    font-size: 1.25rem;
    color: white;
    transition: background 0.3s ease, transform 0.3s ease;
    border-radius: 5px; /* Optional: Adding rounded corners */
}

.btn-custom:hover {
   
    transform: translateY(-2px);
}






/* Container and typography */
.container {
    max-width: 1200px;
    margin: auto;
    padding: 0 15px;
    
  }
  
  .product h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #188389;
    font-weight: 600;
  }

  
 .product p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    font-weight: 600;
  }
  
  .card-img-top {
    width: 100%;
    height: 270px;
    object-fit: contain;
  }
  
  .card-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  
  .card-title {
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
    color: #a2c11c;
  }
  
  .card-text {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .card {
      margin-bottom: 1rem;
    }
  }
 









  

  .text-effect h1 {
    font-weight: 700;
    font-size: 4vw; /* Responsive font-size */
    font-family: Hack, Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-transform: uppercase;
    background: linear-gradient(60deg, #fff, #188389, #fff);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 80%;
    animation: shine 15s linear infinite;
    position: absolute;
    top: 50%;
    left: 5vw; /* Responsive left position */
    transform: translateY(-50%); /* Centers the text vertically */
  }
  
  
  @keyframes shine {
    0% {
      background-position-x: -500%;
    }
    100% {
      background-position-x: 500%;
    }
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .text-effect p {
      font-size: 8vw; /* Adjust font-size for small screens */
      left: 10vw; /* Adjust left position for small screens */
    }
  }
  
  /* Media query for larger screens */
  @media (min-width: 1200px) {
    .text-effect p {
      font-size: 4vw; /* Adjust font-size for large screens */
      left: 3vw; /* Adjust left position for large screens */
    }
  }
  



  /* whats app icon*/
.whatsapp-button-container {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 1000;
}

.whatsapp-icon {
  color: #25d366;
  transition: transform 0.3s ease-in-out;
}

.whatsapp-icon:hover {
  transform: scale(1.2); /* Increase icon size on hover */
}
